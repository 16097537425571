import { mockProducts } from './productsMock';
import { getOpenTimes, getOpeningSoonTimes, getClosedTimes } from './mockTimes';
import { mockStoreEvents } from './storeEventsMock';
import mockStoreConfig from './mockStoreConfig';

const getStoreEvents = (storeIdx: number) =>
  mockStoreEvents()
    .filter(
      (e) =>
        e.store === mockStoreConfig[storeIdx].id &&
        !mockStoreConfig[storeIdx].mobile
    )
    .map((e) => e.id);

export const mockStores = () => [
  {
    name: 'Stellar Test Store',
    id: mockStoreConfig[0].id,
    customer_ordering_enabled: true,
    display_customer_referral: true,
    created_at: '2022-08-25T18:03:20.930461Z',
    modified_at: '2022-08-25T18:03:20.930487Z',
    estimated_wait_time_minutes: 12,
    archived: false,
    etag: '57a880b30bc1996bf45ac504aac4957e6f40d4dc',
    address: mockStoreConfig[0].address,
    visible: true,
    mobile: mockStoreConfig[0].mobile,
    tax_rate: '0.00000',
    subtotal_max: 10000,
    created_by: '662f2a22-acba-40cf-b93b-435d67a686ab',
    modified_by: '662f2a22-acba-40cf-b93b-435d67a686ab',
    menu: '49f93729-06fb-44ed-a5a7-52b99f8a7181',
    latitude: 33.9171322,
    longitude: -118.3349365,
    defer_payments: true,
    ...getOpenTimes(),
    // store_events: [],
    store_events: getStoreEvents(0),

    // preorder_at: null,
    // open_at: null,
    // close_at: null,
  },
  {
    name: 'Stellar Mobile',
    id: mockStoreConfig[1].id,
    customer_ordering_enabled: true,
    display_customer_referral: false,
    created_at: '2022-08-25T18:03:20.930461Z',
    modified_at: '2022-08-25T18:03:20.930487Z',
    estimated_wait_time_minutes: 12,
    archived: false,
    etag: '57a880b30bc1996bf45ac504aac4957e6f40d4dc',
    address: mockStoreConfig[1].address,
    visible: true,
    mobile: mockStoreConfig[1].mobile,
    tax_rate: '0.00000',
    subtotal_max: 10000,
    created_by: '662f2a22-acba-40cf-b93b-435d67a686ab',
    modified_by: '662f2a22-acba-40cf-b93b-435d67a686ab',
    menu: '49f93729-06fb-44ed-a5a7-52b99f8a7181',
    latitude: 34.0239148,
    longitude: -118.2838147,
    ...getOpenTimes(),
    defer_payments: false,
    store_events: getStoreEvents(1),
  },
  {
    name: 'Stellar Pending',
    id: mockStoreConfig[2].id,
    customer_ordering_enabled: true,
    display_customer_referral: false,
    created_at: '2022-08-25T18:03:20.930461Z',
    modified_at: '2022-08-25T18:03:20.930487Z',
    estimated_wait_time_minutes: 12,
    archived: false,
    etag: '57a880b30bc1996bf45ac504aac4957e6f40d4dc',
    address: mockStoreConfig[2].address,
    visible: true,
    mobile: mockStoreConfig[2].mobile,
    tax_rate: '0.00000',
    subtotal_max: 10000,
    created_by: '662f2a22-acba-40cf-b93b-435d67a686ab',
    modified_by: '662f2a22-acba-40cf-b93b-435d67a686ab',
    menu: '49f93729-06fb-44ed-a5a7-52b99f8a7181',
    latitude: 34.0339148,
    longitude: -118.2838147,
    ...getOpenTimes(),
    defer_payments: false,
    store_events: getStoreEvents(2),
  },
  {
    name: 'Stellar Hawthorne',
    id: mockStoreConfig[3].id,
    customer_ordering_enabled: false,
    display_customer_referral: true,
    created_at: '2021-05-14T18:43:37.031000Z',
    modified_at: '2022-08-23T23:04:59.645618Z',
    estimated_wait_time_minutes: 21,
    archived: false,
    etag: '4a2a2238ce11024b4ce59a64459ef7e8feb20355',
    address: mockStoreConfig[3].address,
    visible: true,
    mobile: mockStoreConfig[3].mobile,
    tax_rate: '0.10250',
    subtotal_max: 10000,
    created_by: '8595f6cb-9121-41c2-b61b-5bbd4710093a',
    modified_by: '2235dbb2-313d-4d91-af01-6d46d27c9925',
    menu: '49f93729-06fb-44ed-a5a7-52b99f8a7181',
    latitude: null,
    longitude: null,
    ...getOpeningSoonTimes(),
    defer_payments: true,
    store_events: getStoreEvents(3),
    // preorder_at: makeTime(23),
    // open_at: makeTime(23),
    // close_at: makeTime(24),
  },
  {
    name: 'Stellar LA',
    id: mockStoreConfig[4].id,
    customer_ordering_enabled: false,
    display_customer_referral: true,
    created_at: '2021-05-14T18:43:37.031000Z',
    modified_at: '2022-08-23T23:04:59.645618Z',
    estimated_wait_time_minutes: 21,
    archived: false,
    etag: '4a2a2238ce11024b4ce59a64459ef7e8feb20355',
    address: mockStoreConfig[4].address,
    visible: true,
    mobile: mockStoreConfig[4].mobile,
    tax_rate: '0.10250',
    subtotal_max: 10000,
    created_by: '8595f6cb-9121-41c2-b61b-5bbd4710093a',
    modified_by: '2235dbb2-313d-4d91-af01-6d46d27c9925',
    menu: '49f93729-06fb-44ed-a5a7-52b99f8a7181',
    latitude: null,
    longitude: null,
    ...getClosedTimes(),
    defer_payments: false,
    store_events: getStoreEvents(4),
  },
  {
    name: 'Stellar Downtown',
    id: mockStoreConfig[5].id,
    customer_ordering_enabled: false,
    display_customer_referral: true,
    created_at: '2021-05-14T18:43:37.031000Z',
    modified_at: '2022-08-23T23:04:59.645618Z',
    estimated_wait_time_minutes: 21,
    archived: false,
    etag: '4a2a2238ce11024b4ce59a64459ef7e8feb20355',
    address: mockStoreConfig[5].address,
    visible: true,
    mobile: mockStoreConfig[5].mobile,
    tax_rate: '0.10250',
    subtotal_max: 10000,
    created_by: '8595f6cb-9121-41c2-b61b-5bbd4710093a',
    modified_by: '2235dbb2-313d-4d91-af01-6d46d27c9925',
    menu: '49f93729-06fb-44ed-a5a7-52b99f8a7181',
    latitude: null,
    longitude: null,
    preorder_at: null,
    open_at: null,
    close_at: null,
    defer_payments: false,
    store_events: getStoreEvents(5),
  },
];

export const mockStoresResponse = () => {
  const stores = mockStores();
  const events = mockStoreEvents();
  return { stores, events };
};

export const mockSingleStoreResponse = (url: string) => {
  const storeId = url.slice(17, 54).replace('/', '');
  const store = mockStores().find((s) => s.id === storeId);
  return { store };
};

export const mockStoreMenuResponse = () => ({
  id: '49f93729-06fb-44ed-a5a7-52b99f8a7181',
  name: 'Stellar Menu',
  categories: [
    {
      id: 'ef531a6e-accd-4dea-a455-1d0e25862c25',
      name: 'Pizzas',
      slug: 'pizzas',
      description: 'Each pizza is 12" and feeds one person.',
      products: [
        {
          id: '00079191-74a9-49e3-9442-3f7941c89665',
          product: '6fc79191-74a9-49e3-9442-3f7941c89665',
          price: 795,
          price_max: null,
          modifiers: [
            {
              id: '07c0026f-e5bb-4b3f-99e0-6363c452d2fb',
              choices: [
                {
                  id: 'fc4f6588-9ca4-42fc-ab37-2b91e38397d3',
                  price: 0,
                },
              ],
            },
            {
              id: 'a9200d7f-583d-47aa-9474-0f86eb815009',
              choices: [
                {
                  id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
                  price: 0,
                },
                {
                  id: 'f0107015-ff6a-4883-a927-5622e4deb382',
                  price: 0,
                },
              ],
            },
            {
              id: 'd54171da-a22e-4976-a321-60134c2c5be3',
              choices: [
                {
                  id: 'a8be53ec-b9ae-4739-b09c-0935be89e3f5',
                  price: 0,
                },
                {
                  id: 'e7b2bb2d-03b4-4317-a7d1-ca3709eb8bc9',
                  price: 0,
                },
              ],
            },
          ],
        },
        {
          id: '00031100-65c2-4edf-8189-a80705e52779',
          product: 'a9331100-65c2-4edf-8189-a80705e52779',
          price: 895,
          price_max: null,
          modifiers: [
            {
              id: '2c72b458-da27-43fd-a42f-6bc1d7175839',
              choices: [
                {
                  id: 'df558bf0-e548-4788-b661-02514efb1d30',
                  price: 0,
                },
              ],
            },
            {
              id: 'c5b379e5-046d-4caf-9b09-5d6dad4f566e',
              choices: [
                {
                  id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
                  price: 0,
                },
                {
                  id: '05a5ab64-4026-4f51-81e5-2ec78a3401a9',
                  price: 0,
                },
              ],
            },
            {
              id: 'd54171da-a22e-4976-a321-60134c2c5be3',
              choices: [
                {
                  id: 'bdd8f946-2a73-4304-850a-1f36b209ac8f',
                  price: 0,
                },
                {
                  id: '92898913-dbe8-43e7-9c84-6cf6a3d43f78',
                  price: 0,
                },
              ],
            },
            {
              id: 'd7547c99-a0a2-4051-8b1b-798827994d6b',
              choices: [
                {
                  id: '26232c64-d912-4a97-8173-ceb8bdb4735c',
                  price: 0,
                },
              ],
            },
          ],
        },
        {
          id: '000ff615-e94f-4a17-9d01-a13e79d73cb8',
          product: '662ff615-e94f-4a17-9d01-a13e79d73cb8',
          price: 995,
          price_max: null,
          modifiers: [
            {
              id: '7795b38a-e5a4-42de-a86b-51f125cc27bc',
              choices: [
                {
                  id: '545050db-e885-45a2-a894-a7addc7ea9fb',
                  price: 0,
                },
              ],
            },
            {
              id: '64a9d1b7-3b84-4718-a2e4-3169daf4b21f',
              choices: [
                {
                  id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
                  price: 0,
                },
                {
                  id: 'f977688c-1180-4374-9b9a-73232530c4af',
                  price: 0,
                },
              ],
            },
            {
              id: 'd54171da-a22e-4976-a321-60134c2c5be3',
              choices: [
                {
                  id: '8d56ea2f-80cc-43e8-a4fe-891f5b29089e',
                  price: 0,
                },
                {
                  id: '13c71400-e45a-4e97-8b25-1e34d17b28d0',
                  price: 0,
                },
              ],
            },
            {
              id: '2d73b487-d181-4d5e-8fac-35614cfee8ff',
              choices: [
                {
                  id: 'd66841d9-f518-4c8e-bbd2-4e38efbe3fb0',
                  price: 0,
                },
                {
                  id: '82f158b8-7bad-42c2-b64b-8eed7d8a2a41',
                  price: 0,
                },
              ],
            },
          ],
        },
        {
          id: '000eac1f-213a-473b-93d3-bcde502d49e9',
          product: '0d0eac1f-213a-473b-93d3-bcde502d49e9',
          price: 1195,
          price_max: null,
          modifiers: [
            {
              id: 'f01c11de-58f7-4301-b3d1-828262fdf9f5',
              choices: [
                {
                  id: '7c550c8d-47b7-4902-abda-252f7c7d2d45',
                  price: 0,
                },
              ],
            },
            {
              id: '71f88173-2ebc-4a4a-a011-431c947176ef',
              choices: [
                {
                  id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
                  price: 0,
                },
                {
                  id: '8e3d2b9b-0b0c-44a3-9006-3a26081bed7b',
                  price: 0,
                },
              ],
            },
            {
              id: 'd54171da-a22e-4976-a321-60134c2c5be3',
              choices: [
                {
                  id: '43efeee3-c72f-46e6-9fb4-465410ddf6f0',
                  price: 0,
                },
                {
                  id: 'db61efaa-eb7e-4638-bb07-a2b617f8f57f',
                  price: 0,
                },
              ],
            },
            {
              id: '6e3a1dd1-9d7d-4d2b-9c1d-47509573784c',
              choices: [
                {
                  id: '64eaa61d-ba1a-4e43-b500-6d52ed4b7c59',
                  price: 0,
                },
                {
                  id: 'ee1341a8-491d-44c9-8c0f-2370838bd888',
                  price: 0,
                },
                {
                  id: '94df4d9a-5e97-44bd-b62b-86adf5ee9923',
                  price: 0,
                },
              ],
            },
          ],
        },
        {
          id: '0006b7a2-3522-4d11-a19c-9ae2d5c2ae81',
          product: 'b436b7a2-3522-4d11-a19c-9ae2d5c2ae81',
          price: 1195,
          price_max: null,
          modifiers: [
            {
              id: '564e8532-bd19-4b7a-8c3a-8ea9069fb4de',
              choices: [
                {
                  id: '7df1695f-fd55-4cfb-b98b-dad70f7e9133',
                  price: 0,
                },
              ],
            },
            {
              id: 'fdc37c9a-8f27-43f6-9c6b-788bb73a916f',
              choices: [
                {
                  id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
                  price: 0,
                },
                {
                  id: 'f467499d-f586-4b8f-9eff-d613c7b8ff70',
                  price: 0,
                },
              ],
            },
            {
              id: 'd54171da-a22e-4976-a321-60134c2c5be3',
              choices: [
                {
                  id: '765ace0e-3a7e-48e8-8db2-96f3adee1c77',
                  price: 0,
                },
                {
                  id: '4c291293-3013-457a-9820-77fbacfb054e',
                  price: 0,
                },
              ],
            },
            {
              id: '54dbe5b3-0a1f-4388-89de-8c375b478f18',
              choices: [
                {
                  id: 'c294f0e1-c7d8-4e47-8fa8-9580bfb19e3e',
                  price: 0,
                },
                {
                  id: '27d3266c-81d8-4f0e-a53a-df84d72c7b53',
                  price: 0,
                },
                {
                  id: '226d34d7-0b17-4c08-8100-b15aae4fd876',
                  price: 0,
                },
                {
                  id: 'cf474a6c-5ea2-40b2-a8d1-0d0d0552df5a',
                  price: 0,
                },
                {
                  id: '675af097-782b-47dd-8bcb-89c412e24a87',
                  price: 0,
                },
              ],
            },
          ],
        },
        {
          id: '000454ab-5cc3-43ee-9d78-a8e83a5d4e0b',
          product: '942454ab-5cc3-43ee-9d78-a8e83a5d4e0b',
          price: 1195,
          price_max: null,
          modifiers: [
            {
              id: '7795b38a-e5a4-42de-a86b-51f125cc27bc',
              choices: [
                {
                  id: '545050db-e885-45a2-a894-a7addc7ea9fb',
                  price: 0,
                },
              ],
            },
            {
              id: '64a9d1b7-3b84-4718-a2e4-3169daf4b21f',
              choices: [
                {
                  id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
                  price: 0,
                },
                {
                  id: 'f977688c-1180-4374-9b9a-73232530c4af',
                  price: 0,
                },
              ],
            },
            {
              id: 'd54171da-a22e-4976-a321-60134c2c5be3',
              choices: [
                {
                  id: '8d56ea2f-80cc-43e8-a4fe-891f5b29089e',
                  price: 0,
                },
                {
                  id: '13c71400-e45a-4e97-8b25-1e34d17b28d0',
                  price: 0,
                },
              ],
            },
            {
              id: '2d73b487-d181-4d5e-8fac-35614cfee8ff',
              choices: [
                {
                  id: 'd66841d9-f518-4c8e-bbd2-4e38efbe3fb0',
                  price: 0,
                },
                {
                  id: '82f158b8-7bad-42c2-b64b-8eed7d8a2a41',
                  price: 0,
                },
                {
                  id: '96000227-ce7b-49e2-adc4-1585c3cf528a',
                  price: 0,
                },
              ],
            },
          ],
        },
        {
          id: '0002052d-9655-4ed6-89dc-819a900991bb',
          product: '5352052d-9655-4ed6-89dc-819a900991bb',
          price: 795,
          price_max: 1195,
          modifiers: [
            {
              id: '8dcd51e1-a965-4d5c-ab33-e711e2161b98',
              choices: [
                {
                  id: '26aa5cd8-53cd-4383-bb78-d24c67f7704f',
                  price: 0,
                },
              ],
            },
            {
              id: '4fef18f6-024d-47f1-859f-c01b9c3924ce',
              choices: [
                {
                  id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
                  price: 0,
                },
                {
                  id: 'ebeac286-e04b-4812-a2ff-662eceac4f07',
                  price: 0,
                },
              ],
            },
            {
              id: 'd54171da-a22e-4976-a321-60134c2c5be3',
              choices: [
                {
                  id: '34ceb2ab-6658-4a21-a44d-a1a54d9c8bdd',
                  price: 0,
                },
                {
                  id: '9217546e-918b-4fac-a477-fb13a60ec0bc',
                  price: 0,
                },
              ],
            },
            {
              id: '287aa293-6cfd-4794-8e12-10a4187bbcd9',
              choices: [
                {
                  id: 'fc9839bf-b3f8-4270-9878-a72310556163',
                  price: 100,
                },
                {
                  id: 'bcb8ea09-aaa2-48bc-bd65-05243afa0397',
                  price: 100,
                },
                {
                  id: '4d0ff327-0707-4300-8d0d-9e7985c95787',
                  price: 200,
                },
                {
                  id: '8591841d-a0bb-442e-8f27-ce83eaf445bd',
                  price: 100,
                },
                {
                  id: 'b7ffd1c4-103a-4f9b-9820-106ff800f799',
                  price: 100,
                },
                {
                  id: '679caf16-0a18-4885-8a41-1b16047511ab',
                  price: 100,
                },
                {
                  id: 'fda7ee4a-acde-49f1-8c55-054b9324f723',
                  price: 100,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'f0688353-db6e-4962-a3f7-b5de320f0736',
      name: 'Dips',
      slug: 'dipping-sauces',
      description: 'Go ahead and dunk.',
      products: [
        {
          id: '000d9cd4-3595-4086-be8d-317187fdb599',
          product: 'd3ed9cd4-3595-4086-be8d-317187fdb599',
          price: 95,
          price_max: null,
          modifiers: [],
        },
        {
          id: '000795db-7ef2-48be-8383-866e062ce836',
          product: '985795db-7ef2-48be-8383-866e062ce836',
          price: 95,
          price_max: null,
          modifiers: [],
        },
        {
          id: '000bdb04-6905-49ed-afbf-1fba7baf7b2e',
          product: '823bdb04-6905-49ed-afbf-1fba7baf7b2e',
          price: 95,
          price_max: null,
          modifiers: [],
        },
        {
          id: '000dc514-0143-4ccb-8eb1-5fab56cdf82d',
          product: '3b6dc514-0143-4ccb-8eb1-5fab56cdf82d',
          price: 95,
          price_max: null,
          modifiers: [],
        },
        {
          id: '0000f730-8d15-4fdd-9b80-81aa69a5a713',
          product: '0a70f730-8d15-4fdd-9b80-81aa69a5a713',
          price: 95,
          price_max: null,
          modifiers: [],
        },
        {
          id: '000d1d15-dbe9-4cfe-9e62-a14869b2e47e',
          product: 'bfbd1d15-dbe9-4cfe-9e62-a14869b2e47e',
          price: 95,
          price_max: null,
          modifiers: [],
        },
      ],
    },
    {
      id: '3285ef39-41e5-4bcc-8438-bd26ab4b66b8',
      name: 'Drinks',
      slug: 'drinks',
      description: 'Quench your thirst!',
      products: [
        {
          id: '000fd9b8-6c56-4fa0-ac58-d3e6438f31ad',
          product: '86efd9b8-6c56-4fa0-ac58-d3e6438f31ad',
          price: 275,
          price_max: null,
          modifiers: [],
        },
        {
          id: '000c1c3d-c927-49eb-825c-84ff1d03f9a0',
          product: '989c1c3d-c927-49eb-825c-84ff1d03f9a0',
          price: 275,
          price_max: null,
          modifiers: [],
        },
        {
          id: '000aefbf-ea3d-4bb4-81a3-d94be73c0d5b',
          product: '95faefbf-ea3d-4bb4-81a3-d94be73c0d5b',
          price: 195,
          price_max: null,
          modifiers: [],
        },
        {
          id: '000778b9-acdd-42dc-ac1d-879adc4032b7',
          product: '242778b9-acdd-42dc-ac1d-879adc4032b7',
          price: 195,
          price_max: null,
          modifiers: [],
        },
        {
          id: '00016728-2af0-483b-8fb0-1306e0d8119b',
          product: 'ecf16728-2af0-483b-8fb0-1306e0d8119b',
          price: 195,
          price_max: null,
          modifiers: [],
        },
        {
          id: '000f866a-fb1e-4b3f-81ff-e308122e525e',
          product: '19df866a-fb1e-4b3f-81ff-e308122e525e',
          price: 195,
          price_max: null,
          modifiers: [],
        },
        {
          id: 'f5ded82e-4ce4-4ef5-9edf-0220aedadf92',
          product: 'f5ded82e-4ce4-4ef5-9edf-0220aedadf92',
          price: 245,
          price_max: null,
          modifiers: [],
        },
        {
          id: '00000829-3b5b-4274-a2c1-ab8e4b67e3f4',
          product: 'f6700829-3b5b-4274-a2c1-ab8e4b67e3f4',
          price: 295,
          price_max: null,
          modifiers: [],
        },
      ],
    },
    {
      id: '24c2cbc2-7dc6-4f0a-9268-9beb074f7ab9',
      name: 'Extra',
      slug: 'extra',
      description: 'Pizza eating supplements.',
      products: [
        {
          id: '0005ab87-07cb-4631-ae61-7cfa869a67cd',
          product: 'c4e5ab87-07cb-4631-ae61-7cfa869a67cd',
          price: 50,
          price_max: null,
          modifiers: [],
        },
      ],
    },
  ],
  products: mockProducts(),
  version: 51,
});
